//menu hamburger
#menu {
    background: url(iconUrl("svg/hamburger.svg")) no-repeat;
    width: 32px;
    height: 32px;
    border: 0;
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 40vw;
    height: 100vh;
    background: #fff;
    z-index: 1001;
    @include media-breakpoint-down(sm) {
        width: 50vw;
    }
    &__container {
      // Hide menu by default
      transition: transform 150ms ease-in;
      transform: translateX(-100vw);
    }
  
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);
      transform: translateX(-100vw);
      opacity: 0;
      transition: opacity 150ms ease-in;
      z-index: 1000;
  
      &--open {
        transition: opacity 150ms ease-in;
        transform: translateX(0);
        opacity: 1;
      }
    }
    &__mobile {
        padding: 0;
        img {
            padding-left: 2rem;
            padding-bottom: 2rem;
        }
        .navbar {
            #navbarSupportedContent {
                ul {
                    list-style: none;
                    width: 100%;
                    
                    li {
                        padding: 1rem 0 1rem 1rem;
                        border-bottom: 1px solid $colorGreyLight;
                        width: 100%;
                        &:last-child {
                            border-bottom: 0;    
                        }
                        a {
                            font-family: $fontAvenirHeavy;
                            color: $colorGreyText;
                            line-height: 20px;
                            letter-spacing: 1.6px;
                            font-size: map-get($text, body2 );
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
    &--open {
      transition: transform 150ms ease-in;
      transform: translateX(0);
  
      .menu {
        &__title {
          text-align: left;
        }
      }
  
      .dropdown--menu.show {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
  .navbar {
      padding: 0;
  }