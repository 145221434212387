.huicalendar.huicalendar1.huicalendar2 {
  position: relative;
  font-family: $fontCD_roman;
  color: #000;
  width: 100%;
  padding: 2px;
  margin: 0 auto;
  background: #fff;
  th, td {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    line-height: 18px;
    height: 18px;
    width: calc(100% / 9);
    font-family: $fontAvenirRoman;
  }
  td {
    cursor: auto;
    &:hover {
      background: #fff;
    }
    &.enabled {
      color: $colorPrimaryHover;
    }
  }
  .left {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url('../png/img.gif') -16px 0 no-repeat;
    z-index: 10;
  }
  .right {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url('../png/img.gif') -32px 0 no-repeat;
    z-index: 10;
  }
  thead {
    position: relative;
    tr {
      th {
        background: #fff;
      }
      &.arrows{
        position: relative;
        top: -44px;
        height: 40px;
        th {
          &:first-child {
            position: absolute;
            left: 10px;
            top: 10px;
          }
          &:last-child {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: #000;
        font-weight: 900;
      }
    }
  }
  .week {
    height: 60px;
    th {
      color: #000;
      font-weight: 900;
    }
  }
  .month {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-size: map-get($text, title3);
    cursor: pointer;
    ul {
      display: none;
      position: absolute;
      top: 16px;
      left: -1px;
      width: 200%;
      margin: 0;
      padding: 0;
      border: 1px solid #ccc;
      background: #fff;
      z-index: 999;
      li {
        float: left;
        width: 50%;
        list-style: none;
        cursor: pointer;
        &:hover {
          background: #e8ecef;
        }
      }
    }
  }
  .year {
    position: absolute;
    top: -22px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 75%;
    font-size: map-get($text, price);
    cursor: pointer;
  }
  .border-top {
    border-top: 1px solid #ccc;
  }
}