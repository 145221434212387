.fade:not(.show) {
    opacity: 1;
}
.modal-backdrop {
    opacity: .5!important;
}
.modal-content {
    background-color: transparent;
    .modal-header {
        margin-top: 2rem;
        border-bottom: 0;
        .close {
            background-color: white!important;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            border-radius: 5px;
            span {
                line-height: 0;
                margin-top: 2px;
            }
        }
    }
    .modal-body {
        .b-carousel-detail-villa-rental {
            padding: 0;
            background: transparent;
        }
    }
}
.wrap-modal-slider {
    padding: 0 30px;
    opacity: 0;
    transition: all 0.3s;
  }
  
  .wrap-modal-slider.open {
    opacity: 1;
  }

.modal-dialog {
    max-width: 66%;
    width: auto;
    margin: 30px auto;
}

@media (max-width: 768px) {
    .modal-dialog {
        max-width: 100%;
    }
    .modal-content {
        .modal-header {
            .close {
                width: 45px;
                height: 45px;
            }
        }
    }
}


.modal-body img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.modal-backdrop.show {
    background-color: white;
    opacity: 1 !important;
}

.modal-content {
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.modal-header {
    position: sticky;
    z-index: 1050;
    background: inherit;
}
