.container-footer {
    background: black;
    padding: 3rem 10rem 3rem 1.5rem;
    height: 100%;
    @include media-breakpoint-down(md) {
        padding: 3rem 2rem;
    }
    .container-img-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        .b-img-footer {
            .logo-footer {
                width: 259px;
                padding-bottom: 3.5rem;
                @include media-breakpoint-down(md) {
                    padding-bottom: 2rem;
                }
            }
            ul {
                list-style: none;
                display: flex;
                margin: auto;
                width: 100%;
                padding: 0;
                justify-content: center;
                @include media-breakpoint-down(md) {
                    margin-bottom: 3rem;
                }
                p {
                  a {
                    text-decoration: none;
                  }
                }
                li {
                    display: inline;
                    padding: 0 1.5rem;
                    .icon-footer-facebook {
                        width: 17px;      
                    }
                    .icon-footer-twitter {
                        width: 32px;
                    }
                    .icon-footer-instagram{
                        width: 32px;
                    }
                }
            }
        }  
    }
    .b-link-footer {
       ul {
           list-style: none;
           padding: 0;
           margin-bottom: 10px;
           @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }
           li {
               display: inline;
               color: white;
               a {
                   color: white;
                   font-family: $fontAvenirHeavy;
                   line-height: 20px;
                   letter-spacing: 1.6px;
                   font-size: map-get($text, body2 );
                   text-transform: uppercase;
                   padding-right: 1rem;
                   text-decoration: none;
               }
               &::after {
                content: "|";
                font-size: 14px;
                color: white;
                padding-right: 1rem;
            }
            &:last-child {
                &::after {
                    content: "";
                    font-size: 0;
                }
            }
           }
           &:first-child li{
               &::after {
                   content: "";
                   font-size: 0px;
               }
           }
           &:nth-child(3n+0) {
               text-transform: uppercase;
               color: white;
           }
           &:nth-child(4n+0) li {
               &::after {
                padding-left: 1rem;
               }
            }
       } 
       p {
           color: white;
           font-family: $fontAvenirHeavy;
            font-size: map-get($text, body2 );
            line-height: 20px;
            letter-spacing: 1.6px;
            margin-top: 5px;
            margin-bottom: 0;
            &:last-child {
                margin-top: 1rem;
                
            }
       }
       .content-img-cards {
        @include media-breakpoint-down(md) {
            padding-top: 1rem;
        }
            .icon-cards {
                width: 68px;
                margin-top: 10px;
                padding: 0 1rem
                0 0;
            }
       }
    }
}