@mixin title1 {
    font-family: $fontAvenirHeavy;
    font-size: map-get($text, littleBody );
    color: $colorGreyText;
    text-transform: uppercase;
}
@mixin title2 {
    font-family: $fontCD_roman;
    font-size: map-get($text, title3 );
}
@mixin title3 {
   font-family: $fontAvenirHeavy;
   font-size: map-get($text, body2 );
   text-transform: uppercase;
   color: $colorGreyText; 
   letter-spacing: 1.4px;
}