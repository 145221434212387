@mixin buttonPrimary {
    width: 260px;
    height: 56px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: $fontAvenirBlack;
    font-size: map-get($text, body2 );
    background: $colorPrimary;
    border-radius: 50px;
    border: 0;
    text-transform: uppercase;
    &:hover {
        background: $colorPrimaryHover;
        color: white;
        text-decoration: none;
    }
}
@mixin btnPrimary {
    width: 260Px;
    height: 56px;
    color: white;
    font-family: $fontAvenirBlack;
    font-size: map-get($text, body2 );
    background: $colorPrimary;
    border-radius: 50px;
    border: 0;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
        background: $colorPrimaryHover;
        color: white;
        text-decoration: none;
    }
}
@mixin buttonLight {
    background: $colorGreyBackground;
    width: 258px;
    height: 54px;
    text-transform: uppercase;
    border-radius: 50px;
    border: 1px solid $colorGreyText;
    font-family: $fontAvenirBlack;
    font-size: map-get($text, body2 );
    text-decoration: none;
    &:hover {
        background: $colorSecondaryHover;
        border: 0;
        color: white;
        text-decoration: none;
    }
}
@mixin buttonLike {
    background: white;
    text-transform: uppercase;
    border-radius: 50px;
    border: 1px solid $colorGreyText;
    font-family: $fontAvenirBlack;
    font-size: map-get($text, body2 );
    color: $colorGreyText;
    height: 54px;
    padding: 1rem 3rem 1rem 4rem;
    letter-spacing: 0.3px;
    text-decoration: none;
    &::before {
        content: "";
        background: url(iconUrl("svg/heart-icon.svg"));
        width: 25px;
        height: 21px;
        position: absolute;
        margin-left: -35px;
    }
    &:hover {
        background: $colorSecondaryHover;
        border: 0;
        color: white;
        text-decoration: none;
        &::before {
            content: "";
            background: url(iconUrl("svg/heart-white.svg"));
            width: 25px;
            height: 21px;
            position: absolute;
            margin-left: -35px;
        }
    }
} 
@mixin buttonLightMore {
    background: white;
    text-transform: uppercase;
    border-radius: 50px;
    border: 1px solid $colorGreyText;
    font-family: $fontAvenirBlack;
    font-size: map-get($text, body2 );
    color: $colorGreyText;
    height: 54px;
    padding: 0 4rem 0 3rem;
    letter-spacing: 0.3px;
    text-decoration: none;
    &::after {
        content: "";
        background: url(iconUrl("svg/more-grey.svg"));
        width: 22px;
        height: 21px;
        position: absolute;
        margin-left: 13px;
    }
    &:hover {
        background: $colorSecondaryHover;
        border: 0;
        color: white;
        text-decoration: none;
        &::after {
            content: "";
            background: url(iconUrl("svg/more-white.svg")) no-repeat;
            width: 25px;
            height: 21px;
            position: absolute;
        }
    }
} 
@mixin btn-arrow {
    border: 0;
    background: transparent;
    color: $colorSecondary;
    text-transform: uppercase;
    font-size: map-get($text, body2 );
    font-family: $fontAvenirBlack;
    text-decoration: none;
    &:hover {
        color: $colorSecondaryHover;
        text-decoration: underline;
    }
    &::after {
        content: "";
        background: url(iconUrl("svg/arrow-secondary.svg"));
        width: 15px;
        height: 10px;
        position: absolute;
        margin: 4px 0 0 7px;
    }
}
.btn--primary {
    @include buttonPrimary();
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 3rem;
}
