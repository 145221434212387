.first-nav {
    height: 2.5rem;
    padding: 0px 1.5rem;
    background-color: rgb(245, 245, 245);
    ul {
        display: flex;
        align-items: center;
        height: 100%;
        margin: auto;
        li {
            margin-left: 2rem;
            // &:first-child {
            //     &::after {
            //         content: "";
            //         width: 1px;
            //         height: 20px;
            //         background: $colorGreyText;
            //         position: absolute;
            //         margin-left: 1rem;
            //     }
            // }
            a {
                color: $colorGreyText;
                text-transform:uppercase ;
                font-family: $fontAvenirRoman;
                font-size: map-get($text, body2 );
                line-height: 22px;
                letter-spacing: 0.3px;
                text-decoration: none;
                &:hover {
                    color: $colorSecondaryHover;
                    text-decoration: none;
                }
            }
        }
    }
}
.second-nav {
    padding: .7rem 1.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $colorGreyLight;
    .logo-header {
        img {
            width: 163px;
        }
    }
    ul {
        li {
            padding-left: 1.8rem;
            a {
                font-family: $fontAvenirRoman; 
                letter-spacing: 0.3px;
                line-height: 26px;
                font-size: map-get($text, body );
                text-transform: uppercase;
                color: $colorGreyText;
                text-decoration: none;
                &:hover {
                    color: black;
                    text-decoration: none;
                    border-bottom: 1px solid black;
                    padding-bottom: .4rem;
                }
            }
        }
    }
}
