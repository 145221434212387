.container-search-module-home {
    background: $colorGreyBackground;
    display: block;
    width: 100%;
    padding-bottom: 4.6rem;
    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
        border-bottom: 1px solid $colorGreyLight;
    }
    .b-search-module {
        background: white;
        position: relative;
        margin-top: -8%;
        padding: 1.88rem;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
        h1 {
            font-size: map-get($text, title1 );
            font-family: $fontCD_roman;
            color: black;
            text-align: center;
            padding: 1.88rem 0 1rem;
            line-height: 72px;
            @include media-breakpoint-down(sm) {
                display: block;
            }
            @media screen and (max-width: 568px) {
                font-size: 32px;
                line-height: 42px;
            }
        }
        .search-choice {
            display: block;
            text-align: center;
            ul {
                list-style: none;
                padding: 0;
                li {
                    display: inline;
                    span {
                        color: $colorSecondary;
                        font-size: map-get($text, body2 );
                        text-transform: uppercase;
                        font-family: $fontAvenirBlack;
                        &:hover {
                            color:$colorSecondaryHover;
                        }
                    }
                    &:not(:last-child) {
                        margin-right: 1.5rem;
                    }
                }
            }
        }
        .filter-search-home {
            display: block;
            margin: auto;
            width: 100%;
            form {
                .b-filters, .b-filters2 {
                    display: block;
                    align-items: center;
                    select#type-filter, select#price-filter, select#locations-filter, select#bedrooms-filter, select#features-filter, select#arrival-filter, select#features-filter {
                        color: $colorGreyText;
                        font-size: map-get($text, body4 );
                        text-transform: uppercase;
                        border: 0!important;
                        font-family: $fontAvenirHeavy;
                        line-height: 18px;
                        letter-spacing: 1.4px;
                        padding: 0 .5rem!important;
                        margin: 0 .5rem 1.5rem!important;
                        appearance: none;
                        background: url("../svg/chevron-search-home.svg") no-repeat;
                        background-size: 11px 6px;
                        background-position: right;
                    }
                    select#price-filter {
                        padding: 0 1rem 0 .5rem!important;
                    }
                }
                .b-filters {
                    .filters-search {
                        align-items: center;
                        display: flex;
                        margin: auto;
                        justify-content: center;
                        flex-wrap: wrap;
                        input[name=daterange] {
                            width: 11rem!important;
                            &::after {
                                content: ">";
                            }
                        }
                        .ms-parent {
                            color: $colorGreyText;
                            font-size: map-get($text, body4 );
                            text-transform: uppercase;
                            border: 0!important;
                            font-family: $fontAvenirHeavy;
                            line-height: 18px;
                            letter-spacing: 1.4px;
                            padding: 0 .5rem!important;
                            margin: 0 0 1.5rem 0!important;
                            width: auto!important;
                            position: relative;
                            button {
                                position: relative;
                                padding: 0 1.5rem;
                                &::after {
                                    content: "";
                                    background: url(iconUrl("svg/chevron-search-home.svg"))no-repeat;
                                    position: absolute;
                                    width: 11px;
                                    height: 6px;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    margin: auto -1px auto;
                                }
                            }
                            .ms-choice {
                                border: 0;
                                position: relative;
                                color: $colorGreyText;
                                height: 24px;

                                .icon-caret {
                                    border-color: 0;
                                    border-style: 0;
                                    position: relative;

                                }
                            }
                            .ms-choice>span {
                                position: relative;
                                padding-left: 0;
                                text-transform: uppercase;
                            }
                            .ms-choice>span.placeholder {
                                position: relative;
                                padding-left: 0;
                                text-transform: uppercase;
                                color: $colorGreyText;
                                background: white;
                                opacity: 1;
                            }
                        }
                    }
                }
                .b-filters2 {
                    display: block;
                    align-items: center;
                    .filters-search {
                        align-items: center;
                        width: 85%;
                        display: flex;
                        margin: auto;
                        justify-content: center;
                        flex-wrap: wrap;
                        @include media-breakpoint-down(lg) {
                            width: 95%;
                        }
                    }

                }
                .b-filters2 {
                    display: none;
                }
                .b-clear-filter {
                    display: flex;
                    width: 100%;
                    margin: auto;
                    justify-content: center;
                }
                .search-engine {
                    display: inline-block;
                    margin-bottom: 2rem;
                    #site-search {
                        width: 417px;
                        height: 56px;
                        border-top-left-radius: 15px 15px;
                        border-bottom-left-radius: 15px 15px;
                        border: 1px solid $colorGreyLight;
                        @media screen and (max-width: 554px) {
                            width: 300px;
                        }
                        @media screen and (max-width: 442px) {
                            width: 250px;
                        }
                        @media screen and (max-width: 390px) {
                            width: 214px;
                        }
                        &::placeholder {
                            padding-left: 15px;
                        }
                    }
                    button {
                       width: 79px;
                       height: 56px;
                       background: $colorPrimary;
                       border: 0;
                       border-top-right-radius: 15px 15px;
                       border-bottom-right-radius: 15px 15px;
                       float: right;
                       &::after {
                           content: "";
                           background: url(iconUrl("svg/search-icon-white.svg"))no-repeat;
                           width: 23px;
                           height: 24px;
                           position: absolute;
                           margin-left: -12px;
                           margin-top: -11px;
                       }
                    }
                }
                .clear-filter {
                    display: block;
                    justify-content: end;
                    float: right;
                    margin-right: 15rem;
                    @include media-breakpoint-down(lg) {
                        margin-right: 5rem;
                    }
                    @media screen and (max-width: 568px) {
                        margin-right: 3rem;
                    }
                    a {
                        color: $colorSecondary;
                        &:hover {
                            color: $colorSecondaryHover;
                        }
                    }
                    label {
                        color: $colorSecondary;
                        font-family: $fontAvenirRoman;
                        letter-spacing: 0.3px;
                        line-height: 22px;
                        font-size: map-get($text, body2 );
                        padding-left: 10px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}
.container-corcoran-choice {
    background: white;
    h2 {
        padding: 4.25rem 0 2.5rem;
        text-align: center;
        @include media-breakpoint-down(sm) {
            padding: 3rem 2rem;
        }
    }
    .b-black-choice {
        background: black;
        padding: 4.2rem 0;
        @include media-breakpoint-down(md) {
            padding: 2rem;
        }
        .content-benefits {
            color: white;
            padding: 0 3.6rem;
            text-align: center;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            h3 {
                font-family: $fontCD_roman;
                font-size: map-get($text, title4 );
                margin-top: 1.5rem;
                letter-spacing: 0.3px;
                line-height: 32px;
            }
            p {
                padding: 0 .5rem ;
                font-family: $fontAvenirRoman;
                font-size: map-get($text, body2 );
                letter-spacing: 0.3px;
                line-height: 22px;
            }
        }
    }
}
.container-services-included {
    background: $colorGreyBackground;
    padding-bottom: 4.6rem;
    @include media-breakpoint-down(md) {
        padding: 0 2rem 4.6rem 2rem ;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 2rem 3rem ;
    }
    .b-services-included {
        h2 {
            padding: 4.25rem 0 2.5rem;
            text-align: center;
            @include media-breakpoint-down(sm) {
                padding: 3rem 0;
            }
        }
        .content-services {
            text-align: center;
            p {
                margin-top: 1.5rem;
                padding: 0 2rem;
                @include media-breakpoint-down(md) {
                    padding: 0 .2em;
                }
            }
        }
    }
}
.container-featured-villas {
    margin-bottom: 6rem;
    @include media-breakpoint-down(md) {
        margin-bottom: 3rem;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
    }
    h2 {
        padding: 4.25rem 0 2.5rem;
        @include media-breakpoint-down(sm) {
            padding: 2rem 0;
        }
    }
    .b-featured-villas {
        border: 1px solid $colorGreyLight;
        &:hover {
            border: 1px solid $colorSecondaryHover;
        }
        .img-featured-villas {
            img {
                height: 12.31rem;
                width: 100%;
                object-fit: cover;
                object-position: center 10%;
                z-index: 0;
            }
        }
        .content-featured-villas {
            a {
                text-decoration: none;
            }
            .content-text {
                padding: 1.5rem;
                align-items: center;
                min-height: 200px;
                .title1 {
                    h4 {
                        @include title1();
                        margin: 0;
                        display: flex;
                        align-items: center;
                    }
                    .start-note {
                        width: 100px;
                    }
                }
                h3 {
                    @include title2();
                    color: black;
                    padding: .7rem 0 .5rem;

                }
                .villas-benefits {
                    font-family: $fontAvenirHeavy;
                    letter-spacing: 1.4px;
                    line-height: 18px;
                    font-size: map-get($text, body4 );
                    color: $colorGreyText;
                    text-transform: uppercase;
                    span {
                        //margin-left: 1rem;
                        position: relative;
                        margin-right: 1rem;
                        &::after {
                            content: "|";
                            font-size: map-get($text, body4 );
                            margin-right: -.8rem;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                        }
                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
            .content-price {
                border-top: 1px solid $colorGreyLight;
                padding: 1em 1.5rem;
                a {
                    text-decoration: none;
                    color: black;
                }
                .rental-price-villa {
                    display: flex;
                    align-items: center;
                    h3 {
                        font-family: $fontAvenirRoman;
                        letter-spacing: 0.3px;
                        line-height: 28px;
                        font-size: map-get($text, price );
                        width: 80%;
                        margin: 0;
                    }
                }
                .favorites {
                    display: flex;
                    align-items: center;
                    img {
                        width: 23px;

                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }
    }
}

.container-newsletter {
    background: black;
    @include media-breakpoint-down(md) {
        padding: 3em 0;
    }
    .b-text-newsletter {
        display: flex;
        align-items: center;
        margin: auto;
        height: 100%;
        .content-text-newsletter {
            h2 {
                text-align: right;
                margin-left: 4rem;
                color: white;
                font-weight: 100px;
                @include media-breakpoint-down(sm) {
                    text-align: left;
                    margin-left: 0;
                }
            }
            p {
                text-align: right;
                color: white;
                margin: 1rem 0 0 4rem;
                font-size: 20px;
                line-height: 28px;
                @include media-breakpoint-down(md) {
                    margin: 1rem 0;
                }
                @include media-breakpoint-down(sm) {
                    text-align: left;
                }
            }
            .container-form {
                margin: 1.5em 0 0 4rem;
                @include media-breakpoint-down(sm) {
                    margin: 1.5rem 0;
                }
                label {
                    display: block;
                    color: white;
                    @include labelForm();
                }
                .input-form-field {
                    @include inputForm();
                    width: 100%;
                    padding-left:1rem;
                    &::placeholder{
                        padding-left: 0;
                    }
                }
                button {
                    position: absolute;
                    height: 56px;
                    border: 0;
                    background: white;
                    border-top-right-radius: 15px 15px;
                    border-bottom-right-radius: 15px 15px;
                    margin-left: -40px;
                    img {
                        width: 21px;
                    }
                }
            }
        }
    }

    .content-img-newsletter {
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            height: 100%;
        }
        img {
            width: 100%;
            margin-top: -2.7rem;
            padding-bottom: 6.6rem;
            @include media-breakpoint-down(md) {
                padding: 0;
                margin: 0;
            }
        }
    }
}
.container-bloc-text {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
    background: $colorGreyBackground;
    @include media-breakpoint-down(md) {
        padding: 5rem 2rem;
    }
    .b-bloc-img-left {
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            height: 100%;
        }
        @media screen and (max-width: 568px) {
            padding-bottom: 2rem;
        }
        img {
            width: 100%;
        }
    }
    .b-bloc-text-right {
        padding-right: 6rem;
        @include media-breakpoint-down(md) {
            padding: 0;
        }
        h2 {
            margin-top: 3rem;
            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }
        p {
            font-size: map-get($text, bigBody );
            color: $colorGreyText;
            padding-top: 1rem;
        }
        .button-light {
            @include buttonLight();
            margin-top: 1rem;
        }
    }
    .b-bloc-text-left {
        text-align: right;
        padding-left: 6rem;
        @include media-breakpoint-down(md) {
            padding: 0;
        }
        h2 {
            margin-top: 3rem;
            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }
        p {
            font-size: map-get($text, bigBody );
            color: $colorGreyText;
            padding-top: 1rem;
            @media screen and (max-width: 568px) {
                padding-bottom: 2rem;
            }
        }
    }
    .b-bloc-img-right {
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            height: 100%;
        }
        @media screen and (max-width: 568px) {
            padding-bottom: 2rem;
        }
        img {
            width: 100%;
        }
    }
}
.container-blog {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
    @include media-breakpoint-down(md) {
        padding: 5rem 2rem;
    }
    @media screen and (max-width: 568px) {
        padding: 4rem 2rem 2rem;
    }
    h2 {
        margin-bottom: 3rem;
    }
    .b-blog {
        .b-img-blog {
            img {
                width: 100%;
            }
        }
        .content-text-blog {
            padding: 1.5rem 0;
            h4 {
                @include title1();
            }
            h3 {
                @include title2();
            }
            p {
                font-size: map-get($text, body2 );
                min-height: 35px;
                overflow: hidden;
            }
            .btn-arrow {
                float: right;
                margin-right: 20px;
                @include btn-arrow();
            }
        }
        @media screen and (max-width: 568px) {
            margin-bottom: 3rem;
        }
    }
}
.container-comment-home {
    background: $colorGreyBackground;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;

}

input[name="daterange"] {
    width: max-content;
    background-color: #fff !important;
    color: #646464;
    font-size: 12px;
    text-transform: uppercase;
    border: 0;
    font-family: "avenir heavy";
    letter-spacing: 1.4px;
    line-height: 18px;
    padding: 0 0.5rem;
    margin: 0 0.5rem 1.5rem;
}
#inputEmail{
    margin-bottom: 2rem;
}
#inputPassword {
    margin-bottom: 3rem;
}
.mt-search {
    margin-top: 4.6rem!important;
}
