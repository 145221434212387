.big-carousel-home {
  .item-carousel {
    position: relative;
    padding: 0!important;
    .b-flag-carousel {
      background-color: rgb(0, 0, 0);
      color: rgb(255, 255, 255);
      cursor: pointer;
      display: block;
      font-family: Avenir;
      padding: 20px;
      position: absolute;
      opacity: 0.7;
      right: 0;
      img, picture img{
        @media screen and (max-width: 568px) {
          min-height:300px;
        }
      }
      .villa-name {
        font-family: $fontCD_roman;
        font-size: map-get($text, body );
        margin-right: 1rem;
      }
      .villa-benefits {
        font-family: $fontAvenirHeavy;
        letter-spacing: 1px;
        line-height: 12px;
        font-size: map-get($text, littleBody );
        margin-right: 1rem;
      }
      .villa-price {
        font-family: $fontAvenirBook;
        font-size: map-get($text, body2 );
        text-transform: uppercase;
        margin-right: 1rem;
      }
      .villa-location {
        font-family: $fontCD_roman;
        font-size: map-get($text, body );
        letter-spacing: 0.3px;
        line-height: 20px;
      }
    }
  }

  .slick-next {
    right: 4%;
    &::before {
      content: "";
      background: url(iconUrl("svg/arrow-icon-right.svg")) no-repeat;
      width: 24px;
      height: 45px;
      position: absolute;
      right: 0;
    }
  }
  .slick-prev {
    left: 2%;
    z-index: 2;
    &::before {
      content: "";
      background: url(iconUrl("svg/arrow-icon-left.svg")) no-repeat;
      width: 24px;
      height: 45px;
      position: absolute;
    }
  }
  .slick-dots {
    bottom: 20%;
    li {
      margin: 0;
      height: 15px;
      width: 15px;
      button {
        padding: 3px;
        height: 15px;
        width: 15px;
        &::before {
          font-size: 25px;
          color: white;
        }
      }

    }
    li.slick-active {
      button::before {
        color: white;
      }
    }
  }
  .slick-slide {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
.carousel-villas .slick-prev {
  left: .4rem;
  z-index: 3;
}
.carousel-villas .slick-next {
  right: 1.2rem;
}
.carousel-villas a {
  padding: 0!important;
}
.carousel-villas .slick-next:before {
  content: "";
  background: url("../svg/big-arrow-icon-right.svg");
  width: 13px;
  height: 24px;
  z-index: 2;
  position: absolute;
  top: 0;
  opacity: 1;
}
.carousel-villas .slick-prev:before {
  content: "";
  background: url("../svg/big-arrow-icon-left.svg");
  width: 13px;
  height: 24px;
  z-index: 2;
  position: absolute;
  top: 0;
  opacity: 1;
}
.slick-dotted.slick-slider {
  margin-bottom: 0px;
}
.container-comment-home {
  @include media-breakpoint-down(md) {
    padding: 2rem;
  }
  @media screen and (max-width: 568px) {
    padding: 2rem 2rem 1rem;
  }
  .carousel-comment-home {
    .slick-prev {
      left: -6rem;
      width: 13px;
      height: 24px;
      @include media-breakpoint-down(md) {
        left: -2rem;
      }
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        background: url(iconUrl("svg/black-arrow-left.svg")) no-repeat;
        width: 13px;
        height: 24px;
      }
    }
    .slick-next {
      right: -6rem;
      width: 13px;
      height: 24px;
      @include media-breakpoint-down(md) {
        right: -2rem;
      }
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        background: url(iconUrl("svg/black-arrow-right.svg")) no-repeat;
        width: 13px;
        height: 24px;
      }
    }
    .b-comment-home {
      margin: 0 .5rem;
      .b-img-comment {
        @include media-breakpoint-down(md) {
          display: flex;
          align-items: center;
          height: 100%;
        }
        @include media-breakpoint-down(sm) {
          display: block;
        }
        @media screen and (max-width: 568px) {
          padding-bottom: 2rem;
        }
        a {
          img {
            width: 100%;
          }
        }
      }
      .b-score {
        @media screen and (max-width: 568px) {
          margin-bottom: 2rem;
        }
        img {
          width: 155px;
        }
      }
      .content-text-comment {
        margin-right: 4em;
        padding-top: 1.5rem;
        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
        p:first-child {
          &::before {
            content: "";
            background: url(iconUrl("svg/quote-left.svg")) no-repeat;
            position: absolute;
            width: 31px;
            height: 23px;
            margin-left: -4rem;
            margin-top: -1rem;
            @media screen and (max-width: 568px) {
              margin-top: -2rem;
              margin-left: -1rem;
            }
          }
          &::after {
            content: "";
            background: url(iconUrl("svg/quote-right.svg")) no-repeat;
            position: absolute;
            width: 31px;
            height: 23px;
            margin-left: 3rem;
            margin-top: 1rem;
          }
        }
      }
      .row.m-0 {
        justify-content: center;
      }
    }
  }
}
.carousel-detail-villa-rental {
  .slick-prev {
    left: 0.5rem;
    z-index: 3;
    &:before {
      content: "";
      background: url(iconUrl("svg/big-arrow-icon-left.svg"));
      width: 13px;
      height: 24px;
      z-index: 2;
      position: absolute;
    }
  }
  .slick-next{
    right: 1rem;
    &:before {
      content: "";
      background: url(iconUrl("svg/big-arrow-icon-right.svg"));
      width: 13px;
      height: 24px;
      z-index: 2;
      position: absolute;
    }
  }
  .slick-slide {
    img {
      height: 100%;
      object-fit: contain;
      aspect-ratio: 3/2;
    }
  }
  a {
    position: relative;
  }
  .promo {
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    h3 {
      padding: 0 1rem;
      color: white;
      font-family: $fontAvenirHeavy;
      font-size: 16px;
      text-transform: uppercase;
      margin: 0;
    }
  }
}
.slider-nav {
  padding: 0 2rem;
  .slick-prev {
    left: -0.5rem;
    &:before {
      content: "";
      background: url(iconUrl("svg/black-arrow-left.svg"));
      width: 13px;
      height: 24px;
      z-index: 2;
      position: absolute;
    }
  }
  .slick-next{
    right: 0.5rem;
    &:before {
      content: "";
      background: url(iconUrl("svg/black-arrow-right.svg"));
      width: 13px;
      height: 24px;
      z-index: 2;
      position: absolute;
    }
  }
}
.slick-initialized .slick-slide {
  display: block;
  padding: 1rem 5px 5px;
  .slider-nav-img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
.container-carousel-villa-real-estate {
  background: black;
  padding: 4rem;
  .carousel-box {
    margin-top: -10rem;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
  .carousel-real-estate {
    .container-img-real-estate {
      img {
        width: 100%;
      }
    }
    .slick-prev {
      left: -11rem;
      z-index: 3;
      @include media-breakpoint-down(md) {
        left: -3.5rem;
      }
      &:before {
        content: "";
        background: url(iconUrl("svg/big-arrow-icon-left.svg"));
        width: 24px;
        height: 44px;
        z-index: 2;
        position: absolute;
      }
    }
    .slick-next{
      right: -10rem;
      @include media-breakpoint-down(md) {
        right: -2rem;
      }
      &:before {
        content: "";
        background: url(iconUrl("svg/big-arrow-icon-right.svg"));
        width: 24px;
        height: 44px;
        z-index: 2;
        position: absolute;
      }
    }
  }
  .b-detail-list-villa {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 2rem;
    ul {
      list-style: none;
      padding: 0;
      .detail-list-villa {
        display: inline-block;
        .el-d-list-villa {
          display: block;
          margin: auto;
          padding: 1rem;
          img {
            width: 32px;
            margin: auto;
            display: block;
          }
          span {
            display: block;
            text-align: center;
            margin-top: .5rem;
            color: white;
            text-transform: uppercase;
            font-size: map-get($text, body4 );
          }
        }

      }
    }
  }
}
.b-img-blog {
  .slick-dots {
    bottom: 10px;
    li {
      margin: 0;
      height: 15px;
      width: 15px;
      button {
        padding: 3px;
        height: 15px;
        width: 15px;
        &::before {
          font-size: 25px;
          color: white;
        }
      }

    }
  }
}
.container-calendar {
  .slick-list {
    margin-left: -1.2rem;

  }
  .slick-track {
    padding: 1rem 0 0;
  }
  .slick-prev {
    @media screen and (max-width: 991px) {
      left: -39px;
    }
    &:before {
      content: "";
      background: url(iconUrl("svg/black-arrow-left.svg"));
      width: 23px;
      height: 23px;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
    }
  }
  .slick-next {
    right: -1.1rem;
    @media screen and (max-width: 991px) {
    right: -2.1rem;
    }
    &:before {
      content: "";
      background: url(iconUrl("svg/black-arrow-right.svg"));
      width: 23px;
      height: 23px;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
    }
  }
}

.big-carousel-home .item-carousel,
.carousel-villas .item-carousel,
.carousel-comment-home .item-carousel,
.carousel-real-estate .item-carousel,
.container-calendar .item-carousel,
.carousel-detail-villa-rental .item-carousel,
.slider-nav .item-carousel{
  opacity:0;
  transition: opacity 0.4s ease;
}
.big-carousel-home .item-carousel.loaded,
.carousel-villas .item-carousel.loaded,
.carousel-comment-home .item-carousel.loaded,
.carousel-real-estate .item-carousel.loaded,
.container-calendar .item-carousel.loaded,
.carousel-detail-villa-rental .item-carousel.loaded,
.slider-nav .item-carousel.loaded{
  opacity:1;
}
