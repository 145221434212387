@mixin labelForm() {
    font-family: $fontAvenirBlack;
    text-transform: uppercase;
    font-size: map-get($text, littleBody );
}
@mixin inputForm() {
    height: 56px;
    border-radius: 15px;
    border: 0;
    &::placeholder {
        font-family: $fontAvenirRoman;
        font-size: map-get($text, body );
        letter-spacing: 0.3px;
        line-height: 26px;
        color: $colorGreyText;
        padding-left: 1em;
    }
}
