$colorPrimary: #c0535c;
$colorPrimaryHover: #aa424f;
$colorSecondary: #4a769d;
$colorSecondaryHover: #396186;
$colorGreyText: #646464;
$colorGreyBackground: #f5f5f5;
$colorGreyLight: #e5e5e5;
$colorGreyDisabled: #8c8c8c;
$colorRedError: #e50214;

