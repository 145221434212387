.container-detail-villa-rental {
    padding: 3rem 0;
}
.b-detail-villa-rental-left {
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
-webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
-moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
}
.b-carousel-detail-villa-rental {
    background: $colorGreyBackground;
    padding: 1.5rem;
}
.container-content-d-villa-rental {
    padding: 3rem 0 0;
     h2,h1 {
        padding: 0 1.5rem;
        font-family: $fontCD_roman;
     }
    h3 {
        @include title3();
        padding: 0 1.5rem;
    }
    .discount {
        padding: 0!important;
    }
    .b-benefits-rent {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $colorGreyLight;
        width: 50%;
        @media screen and (max-width: 1440px) {
            width: 60%;
        }
        @media screen and (max-width: 1120px) {
            width: 55%;
        }
        @media screen and (max-width: 500px ) {
            width: 100%;
        }
        @media screen and (max-width: 401px) {
            padding: 0;
        }
        .el-benefits-rent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 1rem;
            @media screen and (max-width: 1190px ) {
                margin: 0 .5rem;
            }
            @media screen and (max-width: 1065px ) {
                margin: 0 .3rem;
            }
            @media screen and (max-width: 991px ) {
                margin: 0 1rem;
            }
            @media screen and (max-width: 450px ) {
                margin: 0 .5rem;
            }
            &:last-child .img-benefits-rent {
                &::after {
                    display: none;
                }
            }
            .img-benefits-rent {
                min-height: 60px;
                width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                position: relative;
                @media screen and (max-width: 401px) {
                    width: 40px;
                }
                &::after {
                    content: "|";
                    font-size: 15px;
                    color: $colorGreyText;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    margin-right: -1rem;
                    @media screen and (max-width: 1190px ) {
                        margin-right: -.5rem;
                    }
                    @media screen and (max-width: 1065px ) {
                        margin-right: -.3rem;
                    }
                    @media screen and (max-width: 991px ) {
                        margin-right: -1rem;
                    }
                    @media screen and (max-width: 450px ) {
                        margin: -.5rem;
                    }
                }

                img {
                    width: 48px;
                    height: fit-content;
                }
            }
            p {
                text-align: center;
                font-family: $fontAvenirBlack;
                color: black;
                font-size: map-get($text, body3 );
                span {
                    display: block;
                }
            }
        }
    }
    .btn-like {
        @include buttonLike();
        margin-right: 1.5rem;

        @media screen and (max-width: 1440px) {
            padding: 1rem 1rem 1rem 3rem;
        }
        @media screen and (max-width: 1320px) {
            padding: 1rem 1rem 1rem 3.2rem;
        }
        @media screen and (max-width: 1223px ) {
            margin-right: .5rem;
            padding: 1rem .5rem 1rem 2.8rem;
        }
        @media screen and (max-width: 1120px ) {
            margin-right: 0;
            padding: 1rem .8rem 1rem 2.8rem;
            height: 48px;
            font-size: 12px;
        }

        @media screen and (max-width: 768px ) {
            margin: 1.5rem 0 0 1.5rem;
        }
        @media screen and (max-width: 500px ) {
            margin: 1.5rem auto 0 auto;
            display: block;
            width: 80%;
            margin-right: auto;
        }
    }
    .text-details-villa {
        border-bottom: 1px solid $colorGreyLight;
        padding: 0 1.5rem;
        p {
            color: $colorGreyText;
            line-height: 26px;
            letter-spacing: 0.3px;
            padding: 2rem 0;
        }
    }
    .container-rooms-details {
        padding: 0 1.5rem;

        h3 {
            font-family: $fontCD_roman;
            font-size: map-get($text, title2 );
            color: black;
            text-transform: capitalize;
            margin-top: 2rem;
            padding: 0;
        }
        h4 {
            font-family: $fontAvenirHeavy;
            font-size: map-get($text, body );
            color: $colorGreyText;
            text-transform: uppercase;
            margin-top: 1rem;
            position: relative;
            &::after {
                content: "";
                width: 30px;
                height: 2px;
                background: black;
                position: absolute;
                left: 0;
                margin-top: 2rem;
            }
        }
        p {
            color: $colorGreyText;
            line-height: 26px;
            letter-spacing: 0.4px;
            margin-top: 1.7rem;
            padding: 0!important;
        }
    }
    .container-map-villas {
        margin-top: 2rem;
        padding: 0 1.5rem;
        img {
            width: 100%;
        }
    }
    .container-services-included-villa {
        background: $colorGreyBackground;
        h3 {
            font-family: $fontCD_roman;
            font-size: map-get($text, title2 );
            color: $colorGreyText;
            text-transform: capitalize;
            margin-top: 2rem;
            padding: 2rem 1.5rem;
            text-align: center;
        }
        .container-picto-services {
            .el-picto-services {
                display: flex;
                justify-content: center;
                margin: auto;
                padding-bottom: 1.5rem;
            }
            @media screen and (max-width: 568px) {
                display: none;
            }
        }
        .el-text-services {
            p {
                min-height: 56px;
                padding-bottom: 1.5rem;
            }

        }
    }
    .container-a-la-carte {
        h3 {
        font-family: $fontCD_roman;
        font-size: map-get($text, title2 );
        color: black;
        text-transform: capitalize;
        padding: 2rem 1.5rem;
        margin: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0 1.5rem;
            li {
                line-height: 26px;
                letter-spacing: 0.3px;
                margin-bottom: 10px;
            }
        }
        .container-calendar {
            margin: 2rem 5rem;
            padding-top: 1rem;
            @media screen and (max-width: 1335px) {
                margin: 2rem 3rem;
            }
        }
        .container-calendar-booking {
            padding: 2.5rem 2.5rem;
            border: 1px solid #000;
            margin: 0 1.5rem;
            @media screen and (max-width: 1400px) {
                margin: 0 1rem;
            }
            @media screen and (max-width: 991px) {
                margin: 0 .5rem;
            }
            thead {
                tr {
                    .month, .year {
                        font-family: $fontCD_roman;
                        font-size: 24px;
                    }
                }
            }
        }
        .legend-calendar {
            padding: 0 1.5rem 1.5rem;
            .b-legend-calendar {
                padding-bottom: 1rem;
                .available {
                    width: 13px;
                    height: 13px;
                    background: black;
                    border: 1px solid $colorGreyText;
                    border-radius: 5px;
                    margin-right: 10px;
                    float: left;
                    margin-top: 4px;
                }
                span {
                    font-size: map-get($text, body2 );
                    color: $colorGreyText;
                }
                .notAvailable {
                    width: 13px;
                    height: 13px;
                    background: $colorPrimary;
                    border: 1px solid $colorGreyText;
                    border-radius: 5px;
                    margin-right: 10px;
                    float: left;
                    margin-top: 4px;
                }
            }
        }
        .container-rates {
            background: $colorGreyBackground;
            padding: 1.5rem 3rem;
            .rates-left {
                h4 {
                    font-family: $fontCD_roman;
                    font-size: map-get($text, title4 );
                }
                ul {
                    padding: 0;
                    margin-top: 1.5rem;
                    li {
                        color: $colorGreyText;
                    }
                }
            }
            .rates-right {
                #rates {
                    color: black;
                    font-size: map-get($text, title4);
                    border: 0;
                    font-family: $fontAvenirRoman;
                    padding: 0 .5rem;
                    background: transparent;
                    border-bottom: 1px solid $colorGreyText;
                    width: 100%;
                }
                ul {
                    padding: 0;
                    margin-top: 1.5rem;
                    li {
                        color: $colorGreyText;
                    }
                }
            }
        }
    }
}
.container-comment-detail-villa {
    padding: 2rem 0;
    h2 {
        padding-bottom: 1.5rem;
    }
    .b-detail-comment {
        border-bottom: 1px solid $colorGreyLight;
        padding: 3rem 0 2rem;
        .el-img-comment {
            img {
                width: 100%;
            }
        }
        .el-comment-text {
            margin-left: 7rem;
            @include media-breakpoint-down(sm) {
                margin-left: 4rem;
            }
            @media screen and (max-width: 568px) {
                margin-left: 2rem;
                padding-right: 2rem;
            }
            .b-score {
                @media screen and (max-width: 568px) {
                    margin-bottom: 2rem;
                }
                img {
                    width: 155px;
                }
            }
            .content-text-comment {
                margin-right: 4em;
                padding-top: 1.5rem;
                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                }
                 p:first-child {
                    &::before {
                        content: "";
                        background: url(iconUrl("svg/quote-left.svg")) no-repeat;
                        position: absolute;
                        width: 31px;
                        height: 23px;
                        margin-left: -4rem;
                        @media screen and (max-width: 568px) {
                            margin-top: -2rem;
                            margin-left: -1rem;
                        }
                    }
                    &::after {
                        content: "";
                        background: url(iconUrl("svg/quote-right.svg")) no-repeat;
                        position: absolute;
                        width: 31px;
                        height: 23px;
                        margin-left: 3rem;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
    .btn-comment {
        display: flex;
        justify-content: center;
        margin: 2rem 0;
        .btn--comment {
            @include buttonLightMore();
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }
    }
}
.container-addComment {
    background: $colorGreyBackground;
    padding: 2rem 1.5rem;
    h2 {
        margin-bottom: 1.5rem;
    }
    .comment-villa-name {
        .comment-name {
            label {
                @include labelForm();
                display: block;
            }
            input {
                @include inputForm();
                width: 100%;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 1.5rem
                }
            }
        }
        .comment-villa {
            label {
                @include labelForm();
                display: block;
            }
            #villa-select {
                @include inputForm();
                font-family: $fontAvenirRoman;
                font-size: map-get($text, body );
                letter-spacing: 0.3px;
                line-height: 26px;
                color: $colorGreyText;
                padding-left: 1rem;
                padding-right: 1rem;
                width: 100%;
            }
        }

    }
    .text-add-comment {
        margin: 2rem 0;
        label {
            @include labelForm();
            display: block ;
        }
        textarea {
            @include inputForm();
            height: 10rem;
            width: 100%;
            padding-top: 1rem;
        }
    }
    .final-send {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4rem;
        @media screen and (max-width: 568px) {
            display: block;
        }
        .b-score {
            span {
                display: block;
                @include labelForm();
            }
            @media screen and (max-width: 568px) {
                margin-bottom: 2rem;
            }
            img {
                width: 155px;
            }
        }
        .btn--send {
            @include buttonPrimary();
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }
    }
}
.b-detail-villa-rental-right {
    background: $colorGreyBackground;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
    padding: 1.5rem 3rem;
    position: sticky;
    top: 2rem;
    form {
        input, select {
            display: block;
            @include inputForm();
            border-radius: 50px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            color: $colorGreyText;
            margin-bottom: 1rem;
            &::placeholder, option {
                text-align: center;
                text-transform: uppercase;
            }

        }
        input[type=text], select {
            text-align: center;
            text-align-last: center;
          }
        .btn--send {
            @include buttonPrimary();
            width: 100%;
            margin-bottom: 1rem;
        }
        #bedrooms-rental {
            text-align: center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='15' height='15' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='646464'/></g></svg>") no-repeat;
            background-position: right 10px top 50%;
        }
    }
    .button-light {
        @include buttonLight();
        width: 100%;
        color: $colorGreyText;
    }
}
.container-buttons {
    height: 106px;
    padding-right: 5rem;
    border-bottom: 1px solid $colorGreyLight;
    .btn_primary {
        @include btnPrimary();
        display: block;
        padding: 1rem 3rem 1rem 4rem;
    }
    .btn_like {
        @include buttonLike();
        margin-right: 1rem;
        @media screen and (max-width: 568px) {
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }
    @include media-breakpoint-down(md) {
        padding-right: 0;
    }
    @media screen and (max-width: 568px) {
        height: auto;
    }
}
.container-detail-real-estate {
    @include media-breakpoint-down(md) {
        margin: 0 2rem;
    }
    .b-detail-real-estate {
        .b-benefits-real-estate-villa {
            h2 {
                margin-top: 1.5rem;
            }
            h3 {
                font-size: map-get($text , bigBody );
                color: $colorGreyText;
                letter-spacing: 0.3px;
            }
        }
        .price-villa-real-estate {
            font-family: $fontAvenirBook;
            font-size: 40px;
            @include media-breakpoint-up(sm) {
                margin-top: 1.5rem;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 1.5rem;
            }
        }
    }
    margin-bottom: 2.5rem;
}
.container-listing-detail-carousel {
    margin-bottom: 7rem;
    a {
        display: inline-block;
        width: 120px;
        p {
            display: inline-block;
            font-family: $fontAvenirHeavy;
            font-size: map-get($text, body4 );
            color: $colorSecondary;
            &:hover {
                color: $colorSecondaryHover;
                text-decoration: underline;
            }
        }
        img {
            width: 32px;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 1.5rem;
    }
}
.container-details-real-estate {
    padding: 6rem 0;
    .details-left {
        h2 {
            &::after {
                content: "";
                width: 30px;
                height: 2px;
                background: black;
                position: absolute;
                left: 0;
                margin-left: 1rem;
                margin-top: 4rem;
                @media screen and (max-width: 568px) {
                    margin-top: 3rem;
                }
            }
        }
        p {
            margin-top: 1.5rem;
            line-height: 26px;
            letter-spacing: 0.3px;
            padding-right: 2rem;
        }
        .btn-more {
            background: transparent;
            border: 0;
            text-transform: uppercase;
            color: $colorSecondary;
            font-family: $fontAvenirBlack;
            font-size: map-get($text, body3 );
            letter-spacing: 1.5px;
            &:hover {
                color: $colorSecondaryHover;
                text-decoration: underline;
            }
            &::after {
                content: "";
                background: url(iconUrl("svg/more-icon-blue.svg"));
                width: 21px;
                height: 21px;
                position: absolute;
                margin-left: 13px;
                margin-top: -3px;
            }
        }
    }
    .details-right {
        a.back-search {
            display: flex;
            justify-content: flex-end;
            font-family: $fontAvenirHeavy;
            font-size: map-get($text, body4);
            text-transform: uppercase;
            color: $colorSecondary;
            margin-right: 1rem;
            line-height: 2;
            &::after {
                content: "";
                background: url(iconUrl("svg/arrow-right-blue.svg"));
                width: 13px;
                height: 24px;
                position: absolute;
                margin-right: -1rem;
                margin-top: -2px;
            }
            &:hover {
                color: $colorSecondaryHover;
                text-decoration: underline;
            }
        }
        .container-agent  {
            margin-top: 2.5rem;
            background-color: rgb(255, 255, 255);
            border: 1px solid rgb(229, 229, 229);
            display: flex;
            min-height: 140px;
            max-height: 200px;
            margin-bottom: 8px;
            max-width: 400px;
            .img-agent {
                position: relative;
                height: 100%;
                width: 105px;
                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
            .content-agent {
                padding: 1rem;
                h3 {
                    font-family: $fontCD_roman;
                    font-size: map-get($text, title4);
                }
                h4 {
                    font-family: $fontAvenirBlack;
                    font-size: map-get($text, littleBody);
                    text-transform: uppercase;
                    color: $colorGreyText;
                }
                a {
                    color: $colorSecondary;
                    font-family: $fontAvenirRoman;
                    font-size: map-get($text, body2 );
                    margin-top: 4rem;
                    &:hover {
                        color: $colorSecondaryHover;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.container-map-real-estate {
    margin-bottom: 3rem;
    h2 {
        margin-bottom: 2rem;
    }
    .map-location-real-estate {
        img {
            width: 100%;
        }
    }
}
.container-contact {
    margin-top: 5rem;
    margin-bottom: 10rem;
    h2 {
        text-align: center;
        margin-bottom: 2rem;
    }
    h3 {
        text-align: center;
        font-size: $fontAvenirBook;
        font-size: map-get($text, title3 );
        letter-spacing: 1px;
    }
    .contactUs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
        list-style: none;
        justify-content: center;
        padding: 0;
        li {
            width: 200px;
            height: 88px;
            font-size: 0.75rem;
            font-weight: bold;
            padding: 1rem 0px;
            line-height: 1.14;
            letter-spacing: 0.16px;
            text-align: center;
            text-transform: uppercase;
            background-color: rgb(245, 245, 245);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem;
            flex-wrap: wrap;
            a {
                color: black;
                font-size: map-get($text, title4 );
                font-family: $fontAvenirRoman;
            }
        }
    }
    .container-btn-submit {
        display: flex;
        justify-content: center;
        .submit {
            background: $colorGreyLight;
            text-transform: uppercase;
            border-radius: 50px;
            font-family: $fontAvenirBlack;
            font-size: map-get($text, body2 );
            color: white;
            height: 54px;
            width: 260px;
            letter-spacing: 0.3px;
            border: 0;
        }
    }
}
.font-chronicle {
    font-family: $fontCD_roman;
    font-size: 32px;
}
.reservation-form input:not([type="checkbox"]), .reservation-form select {
    height: 56px!important;
}
.reservation-form {
    label {
        font-family: $fontAvenirHeavy;
        font-size: 12px;
    }
}
#reservation-form-gotopaiement {
    width: 100%;
}
.form-group .col-md-12 label{
    font-family: $fontAvenirRoman;
    font-size: 1rem;
    margin-left: .5rem;
}
.link-dark {
    display: flex;
    align-items: center;
    img {
        width: 32px;
        height: auto;
    }
    p {
        margin: 0;
        font-family: $fontAvenirHeavy;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: .8px;
        color: black;
        margin-left: .5rem;
    }
}
