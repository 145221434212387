.slick-lightbox
{
	position:fixed;
	top:0;
	left:0;
	z-index:9999;
	width:100%;
	height:100%;
	background:black;
	-webkit-transition:opacity 0.5s ease;
	transition:opacity 0.5s ease
}
.slick-lightbox .slick-loading .slick-list
{
	background-color:transparent
}
.slick-lightbox .slick-prev
{
	left:15px
}
.slick-lightbox .slick-next
{
	right:15px
}
.slick-lightbox-hide
{
	opacity:0
}
.slick-lightbox-hide.slick-lightbox-ie
{
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter:alpha(opacity=0)
}
.slick-lightbox-hide-init
{
	position:absolute;
	top:-9999px;
	opacity:0
}
.slick-lightbox-hide-init.slick-lightbox-ie
{
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter:alpha(opacity=0)
}
.slick-lightbox-inner
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%
}
.slick-lightbox-slick-item
{
	text-align:center;
	overflow:hidden
}
.slick-lightbox-slick-item:before
{
	content:'';
	display:inline-block;
	height:100%;
	vertical-align:middle;
	margin-right:-0.25em
}
.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption
{
	position:absolute;
	bottom:0;
	left:0;
	text-align:center;
	width:100%;
	margin-bottom:20px
}
.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption
{
	display:block;
	text-align:center
}
.slick-lightbox-slick-item-inner
{
	display:inline-block;
	vertical-align:middle;
	max-width:90%;
	max-height:90%;
	position: relative;
}
.slick-lightbox-slick-img
{
	margin:0 auto;
	display:block;
	max-width:90%;
	max-height:90%;
	opacity: 0;
	transition: opacity 0.3s ease;
}
.slick-lightbox-slick-item-inner::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	background: url("../images/loader.gif") no-repeat center center;
	background-size: cover;
	z-index: 10;
	display: none;
}
.slick-lightbox-slick-item-inner:not(.image-loaded)::before {
	display: block;
}
.slick-lightbox-slick-item-inner:not(.image-loaded) .slick-lightbox-slick-img {
	display: block;
}
.slick-lightbox-slick-caption
{
	margin:10px 0 0;
	color:white
}
.slick-lightbox-close
{
	position:absolute;
	top:15px;
	right:15px;
	display:block;
	height:30px;
	width:30px;
	line-height:0;
	font-size:0;
	cursor:pointer;
	background:transparent;
	color:transparent;
	padding:0;
	border:none
}
.slick-lightbox-close:focus
{
	outline:none
}
.slick-lightbox-close:before
{
	font-family:"slick";
	font-size:30px;
	line-height:1;
	color:white;
	opacity:0.85;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	content:'×'
}
.slick-lightbox {
    .slick-next {
        &:before {
            content: "";
            background: url(iconUrl("svg/arrow-icon-right.svg")) no-repeat;
            width: 24px;
            height: 45px;
            position: absolute;
            right: 0;
        }
    }
    .slick-prev {
        &:before {
            content: "";
            background: url(iconUrl("svg/arrow-icon-left.svg")) no-repeat;
            width: 24px;
            height: 45px;
            position: absolute;
            left: 0;
        }
    }
}
