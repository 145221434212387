.container-rental-list, .container-real-estate-list {
    background: $colorGreyBackground;
    display: block;
    width: 100%;
    padding-bottom: 4.6rem;
    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
        border-bottom: 1px solid $colorGreyLight;
    }
    .b-search-module {
        background: white;
        position: relative;
        margin-top: -8%;
        padding: 1.88rem;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
        h1 {
            font-size: map-get($text, title1 );
            font-family: $fontCD_roman;
            color: black;
            text-align: center;
            padding: 1.88rem 0 1rem;
            @include media-breakpoint-down(sm) {
                display: block;
            }
            @media screen and (max-width: 568px) {
                font-size: 32px;
            }
        }
        .search-choice {
            display: flex;
            justify-content: center;
            ul {
                list-style: none;
                padding: 0;
                li {
                    display: inline;
                    a {
                        color: $colorSecondary;
                        font-size: map-get($text, body2 );
                        text-transform: uppercase;
                        font-family: $fontAvenirBlack;
                        margin: 0 1.5rem 0;
                        &:hover {
                            color:$colorSecondaryHover;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .filter-search-home {
            display: flex;
            justify-content: center;
            form {
                .b-filters {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    select#type-filter, select#price-filter, select#locations-filter, select#bedrooms-filter, select#features-filter {
                        color: $colorGreyText;
                        font-size: map-get($text, body4 );
                        text-transform: uppercase;
                        border: 0;
                        font-family: $fontAvenirHeavy;
                        letter-spacing: 1.4px;
                        line-height: 18px;
                        padding: 0 .5rem;
                        margin: 0 .5rem 1.5rem;
                    }
                }
                .b-clear-filter {
                    display: flex;
                    width: 100%;
                    margin: auto;
                    justify-content: center;
                }
                .search-engine {
                    display: flex;
                    margin-bottom: 2rem;
                    #site-search {
                        width: 417px;
                        height: 56px;
                        border-top-left-radius: 15px 15px;
                        border-bottom-left-radius: 15px 15px;
                        border: 1px solid $colorGreyLight;
                        @media screen and (max-width: 554px) {
                            width: 300px;
                        }
                        @media screen and (max-width: 442px) {
                            width: 250px;
                        }
                        @media screen and (max-width: 390px) {
                            width: 214px;
                        }
                        &::placeholder {
                            padding-left: 15px;
                        }
                    }
                    button {
                       width: 79px;
                       height: 56px; 
                       background: $colorPrimary;
                       border: 0;
                       border-top-right-radius: 15px 15px;
                       border-bottom-right-radius: 15px 15px;
                       float: right;
                       &::after {
                           content: "";
                           background: url(iconUrl("svg/search-icon-white.svg"))no-repeat;
                           width: 23px;
                           height: 24px;
                           position: absolute;
                           margin-left: -12px;
                           margin-top: -11px;
                       }
                    }
                }
                .clear-filter {
                    display: flex;
                    justify-content: end;
                    float: right;
                    label {
                        color: $colorSecondary;
                        font-family: $fontAvenirRoman;
                        letter-spacing: 0.3px;
                        line-height: 22px;
                        font-size: map-get($text, body2 );
                        padding-left: 10px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}
.b-rental-list, .b-real-estate-list {
    .b-filters-list-rental, .b-filters-list-real-estate {
        padding: 2.5rem 0;
        h3 {
            font-family: $fontCD_roman;
            letter-spacing: 0.3px;
            line-height: 32px;
            font-size: map-get($text, title3 );
        }
        select#sort-filter {
            color: $colorGreyText;
            font-size: map-get($text, littleBody);
            border: 0;
            font-family: $fontAvenirRoman;
            padding: 0 .5rem;
            margin: 0 .5rem 1.5rem;
            background: transparent;
            border-bottom: 1px solid $colorGreyText;
        }
    }
}
.container-list-map {
    background: white;
    padding: 1.5rem;
    .b-list-rental-left, .b-list-real-estate-left {
        overflow-y: scroll;
        height: 1000px;
        overflow-x:hidden;
        @include media-breakpoint-down(md) {
            height: auto;
        }
        .b-rental-villas, .b-villas-real-estate {
            border: 1px solid $colorGreyLight;
            margin-bottom: 2rem;
            &:hover {
                border: 1px solid $colorSecondaryHover;
            }
            .img-villas {
                position: relative;
                padding: 0;
                    img {
                        width: 100%;
                        height: 12.31rem;
                        //object-fit: cover;
                        object-position: center 10%;
                        z-index: 0;
                        padding: 0!important;
                    }
                @media (min-width: 1921px) and (max-width: 2299px) {
                    img {
                        height: 16rem;
                    }
                }

                @media(min-width: 2300px) and (max-width: 2561px) {
                    img {
                        height: 20rem;
                    }
                }

                @media (min-width: 2562px) and (max-width: 3440px) {
                    img {
                        height: 25rem;
                    }
                }

                @media (min-width: 3441px) {
                    img {
                        height: 30rem;
                    }
                }
             
                .promo {
                    position: relative;
                    top: -200px;
                    left: 0;
                    height: 24px;
                    background: black;
                    z-index: 3;
                    display: inline-flex;
                    h3 {
                        font-size: 10px;
                        font-family: $fontAvenirHeavy;
                        color: white;
                        margin: 0;
                        padding: 0 1rem;
                        text-transform: uppercase;
                    }
                }
            }
            .flag-villas {
                -webkit-box-align: center;
                align-items: center;
                color: rgb(255, 255, 255);
                display: flex;
                height: 24px;
                font-size: 10px;
                font-weight: 900;
                letter-spacing: 1px;
                line-height: 1.2;
                text-transform: uppercase;
                z-index: 1;
                background-color: rgb(0, 0, 0);
                padding: 6px 24px;
                width: 100%;
                position: relative;
                margin-top: -24px;
                        }
            .content-featured-villas {
                a {
                    text-decoration: none;
                }
                .content-text {
                    padding: 1.5rem;
                    align-items: center;
                    min-height: 200px;
                    .title1 {
                        h4 {
                            @include title1();
                            margin: 0;
                            display: flex;
                            align-items: center;
                        }
                        .start-note {
                            width: 100px;
                        }    
                    }
                    h3 {
                        @include title2();
                        color: black;
                        padding: .7rem 0 .5rem;
    
                    }
                    .villas-benefits {
                        padding-top: 2rem;
                        font-family: $fontAvenirHeavy;
                        line-height: 18px;
                        letter-spacing: 1.4px;
                        font-size: map-get($text, body4 );
                        color: $colorGreyText;
                        text-transform: uppercase;
                        display: inline-block;
                        span {
                            margin-left: 1rem;
                            margin-right: .3rem;
                        }  
                        img {
                            width: 34px;
                        }
                        &::after {
                            content: "|";
                            font-size: 15px;
                            padding-left: 1rem;   
                        }
                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                .content-price {
                    border-top: 1px solid $colorGreyLight;
                    padding: 1em 1.5rem;
                    a {
                        text-decoration: none;
                        color: black;
                    }
                    .rental-price-villa, .real-estate-price-villa {
                        display: flex;
                        align-items: center;
                        h3 {
                            font-family: $fontAvenirRoman;
                            font-size: map-get($text, price );
                            line-height: 28px;
                            letter-spacing: 0.3px;
                            width: 80%;
                            margin: 0;
                        }
                    }
                    .favorites {
                        display: flex;
                        align-items: center;
                        img {
                            width: 23px;
                            
                        }
                    }
                }
            }
            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }
        }
    }
    .b-list-rental-right {
        img {
            width: 100%;
            height: 1000px;
            object-fit: cover;
        }
    }
}
.villas-benefits-real-estate {
    font-family: $fontAvenirHeavy;
    font-size: map-get($text, body4 );
    letter-spacing: 1.4px;
    line-height: 18px;
    color: $colorGreyText;
    text-transform: uppercase;
    span {
        margin-left: 1rem;
        &::after {
            content: "|";
            font-size: map-get($text, body4 );
            padding-left: 1rem;
            
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
    }  
}
.selected2 {
    background-color: $colorGreyLight;
    border: 1px solid $colorGreyText!important;
    .content-featured-villas {
        .content-price-selected {
            border-top: 1px solid $colorGreyText!important;
        }
    }
    
}