.bread_crumb {
  font-family: 'Avenir Roman', sans-serif;
  font-size: 1rem;
}

.single_villa {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.st_barth_info {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.bread_crumb a {
  color: #4A769D;
  text-decoration: none;
}

.bread_crumb a:hover {
  text-decoration: underline;
  color: #396186;
}

.bread_crumb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bread_crumb li {
  display: inline;
  margin-right: 5px;
}

.bread_crumb li+li:before {
  content: ">";
  margin-right: 5px;
  color: #4A769D;
}