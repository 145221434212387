$fontAvenirBlack : "avenir black";
$fontAvenirBook : "avenir book";
$fontAvenirRoman : "avenir roman";
$fontAvenirHeavy: "avenir heavy";
$fontCD_black: "chronicle display black";
$fontCD_lightItalic: "chronicle display light italic";
$fontCD_roman: "chronicle display roman";
$fontCD_semiBold: "chronicle display semibold";

@font-face {
    font-family: $fontAvenirBlack;
    src: url(../fonts/avenir/AvenirLTStd-Black.otf) format("OpenType");
}
@font-face {
    font-family: $fontAvenirBook;
    src: url(../fonts/avenir/AvenirLTStd-Book.otf) format("OpenType");
}
@font-face {
    font-family: $fontAvenirRoman;
    src: url(../fonts/avenir/AvenirLTStd-Roman.otf) format("OpenType");
}
@font-face {
    font-family: $fontAvenirHeavy;
    src: url(../fonts/avenir/Avenir-Heavy.ttf) format("TrueType");
}
@font-face {
    font-family: $fontCD_black;
    src: url(../fonts/chronicle-display/Chronicle-Display-Black.otf) format("OpenType");
}
@font-face {
    font-family: $fontCD_lightItalic;
    src: url(../fonts/chronicle-display/Chronicle-Display-Light-Italic.otf) format("OpenType");
}
@font-face {
    font-family: $fontCD_roman;
    src: url(../fonts/chronicle-display/Chronicle-Display-Roman.otf) format("OpenType");
}
@font-face {
    font-family: $fontCD_semiBold;
    src: url(../fonts/chronicle-display/Chronicle-Display-Semibold.otf) format("OpenType");
}

@import "node_modules/bootstrap/scss/bootstrap";

@import "constants/text-size.scss";
@import "constants/colors.scss";

@import "mixins/url.scss";
@import "mixins/button.scss";
@import "mixins/form.scss";
@import "mixins/titleText.scss";

@import "components/footer.scss";
@import "components/header.scss";
@import "components/slick-theme.scss";
@import "components/slick.scss";
@import "components/carousel.scss";
@import "components/home.scss";
@import "components/menu.scss";
@import "components/list.scss";
@import "components/villa.scss";
@import "components/calendar.scss";
@import "components/modal.scss";
@import "components/lightbox.scss";


body {
    font-family: $fontAvenirRoman;
}
h2 {
    font-size: map-get($text, title1 );
    font-family: $fontCD_roman;
    @media screen and (max-width: 568px) {
        font-size: 32px;
    }
}
#newsletter_Submit{
    background:transparent;
}
